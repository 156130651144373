.app-details-page-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 20px;
    padding: 66px 60px;
    background-color: rgb(242, 242, 247);
    min-height: 100vh;
}

.empty-state {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #26273b;
    padding: 3rem;
}

.error-msg {
    background-color: rgb(242, 242, 247);
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #26273b;
    padding: 3rem;
}

.empty-guide-search-results {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #26273b;
}