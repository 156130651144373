.card {
    width: 287px;
    height: 200px;
    border-radius: 6px;
    fill: #ffffff;
    box-shadow: 0px 2px 6px rgba(38, 39, 59, 0.14);
    cursor: pointer;
    background-color: white;
}

.card-image {
    width: 271px;
    height: 146px;
    border-radius: 6px;
    fill: #38b6a0;
    margin: 9px 8px 0 8px;
}

.card-title {
    width: 271px;
    height: 22px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #26273b;
    text-decoration: none solid rgb(38, 39, 59);
    margin: 0 8px 16px 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}