.toasts {
    position: fixed;
    top: 70px;
    right: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 101;
}

.toast {
    background-color: #f2f2f7;
    color: black;
    border-radius: 6px;
    padding: 8px 32px 8px 16px;
    max-width: 300px;
    position: relative;
    box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.1);
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #26273b;
    animation-name: slidein;
    animation-duration: 0.35s;
    border: 1px solid rgb(182, 183, 202);
}

@keyframes slidein {
    0% {
        transform: translateX(100%);
    }
    60% {
        transform: translateX(-15%);
    }
    80% {
        transform: translateX(5%);
    }
    80% {
        transform: translateX(0);
    }
}

.close-button {
    position: absolute;
    top: 15px;
    right: 12px;
    background: none;
    cursor: pointer;
    border: none;
    font-size: 16px;
    color: grey;
}