.hero {
    height: 250px;
    fill: #dadada;
    background-image: url('../../assets/Rectangle.svg');
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding: 94px 0 93px 0;
    margin-top: 60px;
}

.hero-text h1 {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 46px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
}

.hero-text p {
    font-size: 18px;
}