.guide-card {
    display: flex;
    max-width: 844px;
    height: 115px;
    border-radius: 6px;
    background-color: #ffffff;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);
    margin-bottom: 10px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;
}

.guide-card:hover {
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2)
}

.guide-card-image-container {
    width: 89px;
    height: 73px;
    overflow: hidden;
    position: relative;
    margin: 17px 0 25px 16px;
    display: flex;
    border-radius: 6px;
    background-color: #dadada;
}

.guide-card-image {
    max-width: 100%;
    height: auto;
    display: block;
    object-fit: cover;
    margin: auto;
}

.guide-card-content {
    flex: 1;
    margin: 17px 0 15px 20px;
}

.guide-card-title {
    max-width: 343px;
    height: 26px;
    font-family: 'Open Sans', sans-serif;
    font-size: 18px;
    color: #26273b;
    text-decoration: none solid rgb(38, 39, 59);
    letter-spacing: -0.24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.guide-card-description {
    max-width: 343px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #737394;
    text-decoration: none solid rgb(115, 115, 148);
    line-height: 20px;
    letter-spacing: -0.25px;
    margin-top: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.guide-card-buttons {
    margin-left: 22px;
    margin-right: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
}

.preview-button,
.use-button {
    cursor: pointer;
    width: 120px;
    height: 38px;
    padding: 0px 15px 1px;
    border: 1px solid #9c9db5;
    border-radius: 6px;
    background-color: #ffffff;
    background-size: cover;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #26273b;
    text-decoration: none solid rgb(38, 39, 59);
    text-align: center;
}

.use-button {
    width: 120px;
    height: 38px;
    padding: 0px 15px 1px;
    border-radius: 6px;
    background-color: #38b6a0;
    background-size: cover;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #ffffff;
    text-decoration: none solid rgb(255, 255, 255);
    text-align: center;
}

.preview-button:hover {
    background-color: rgb(240, 240, 245);
    transition: 0.7s;
}

.use-button:hover {
    background-color: rgb(80, 151, 135);
    transition: 0.7s;
}

/* Media query for screens with a width of 768px or larger */
@media screen and (min-width: 768px) {
    .guide-card-title {
        max-width: 230px;
    }
    .guide-card-description {
        max-width: 230px;
    }
}

/* Media query for screens with a width of 1024px or larger */
@media screen and (min-width: 1024px) {
    .guide-card-title {
        max-width: 260px;
    }
    .guide-card-description {
        max-width: 260px;
    }
}

/* Media query for screens with a width of 1366px or larger */
@media screen and (min-width: 1366px) {
    .guide-card-title {
        max-width: 300px;
    }
    .guide-card-description {
        max-width: 300px;
    }
}

