.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
}

.modal-content {
    background-color: #ffffff;
    width: 660px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.3s ease-in, transform 0.3s ease-in;
}

.modal.open .modal-content {
    opacity: 1;
    transform: scale(1);
}

.modal.closed .modal-content {
    opacity: 0;
    transform: scale(0.9);
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 4px 4px 0 0;
    width: 660px;
    height: 63px;
    padding: 0px 20px;
    border-bottom: 1px solid #b6b7cc;
    background-color: #ffffff;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    color: #394042;
    text-decoration: none solid rgb(57, 64, 66);
    font-weight: 600;
}

.modal-header .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.close {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #555;
}

.modal-body {
    text-align: center;
    padding-bottom: 20px;
}

.preview-not-available {
    text-align: center;
    padding: 80px;
    padding-bottom: 100px;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
}

.modal-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 660px;
    height: 78px;
    padding: 0px 16px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top: 1px solid #b6b7cc;
}

.modal-footer .modal-footer-button {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    width: 120px;
    height: 36px;
    padding: 0px 15px 1px;
    border: 1px solid #9c9db5;
    border-radius: 4px;
    font-family: Open Sans;
    font-size: 16px;
    text-decoration: none solid rgb(38, 39, 59);
    text-align: center;
}

.modal-footer .cancel-button {
    background-color: #ffffff;
    color: #26273b;
}

.modal-footer .use-button {
    background-color: #38b6a0;
    color: #ffffff;
}

.modal-footer .cancel-button:hover {
    background-color: rgb(240, 240, 245);
}

.modal-footer .use-button:hover {
    background-color: rgb(80, 151, 135);
}