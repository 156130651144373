.app-selector {
    position: relative;
    user-select: none;
}

.app-list {
    list-style: none;
    padding: 0;
    margin: 0 5px;
    border: 1px solid rgb(182, 183, 202);
    border-top: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #ffffff;
    box-shadow: 0 2px 6px -4px #000;
}

.app-list .app-list-item {
    padding: 16px;
    cursor: pointer;
    display: flex;
    background-color: #ffffff;
    border-bottom: 1px solid rgb(182, 183, 202);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #26273b;
    text-decoration: none solid rgb(38, 39, 59);
}

.app-list .selected-app-li {
    background-color: #f2f2f7;
    border-bottom: 1px solid rgb(182, 183, 202);
}

.app-list li:hover {
    background-color: rgb(242, 242, 247);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}