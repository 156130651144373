.folder-listing-component-container {
    max-width: 450px;
    border-radius: 6px;
    fill: #fcfdff;
    box-shadow: 4px 1px 4px rgba(0, 0, 0, 0.1);
    background-color: rgb(252, 253, 255);
}

.app-name-container {
    display: flex;
    position: relative;
    height: 112px;
    background-color: rgb(252, 253, 255);
    border-bottom: 1px solid rgb(182, 183, 202);
    border-radius: 6px 6px 0 0;
}

.apps-icon {
    width: 21px;
    height: 21px;
    fill: #737394;
    margin: 57px 0px 34px 29px;
}

.app-name {
    max-width: 285px;
    min-height: 22px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #26273b;
    text-decoration: none solid rgb(38, 39, 59);
    margin: 57px 0 32px 18px;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.down-arrow-icon {
    position: absolute;
    right: 0;
    width: 32px;
    height: 32px;
    fill: #121212;
    margin: 52px 25px 25px 40px;
    cursor: pointer;
}

.down-arrow-app-selector-open {
    background-color: #f2f2f7;
    border-radius: 50%;
}

.folder-item {
    display: flex;
    max-width: 450px;
    height: auto;
    background-color: #ffffff;
    background-size: cover;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgb(182, 183, 202);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.sub-category-folder-item {
    display: flex;
    max-width: 450px;
    min-height: 116px;
    background-color: #ffffff;
    background-size: cover;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
    border-bottom: 1px solid rgb(182, 183, 202);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.folder-item-selected {
    background-color: #f2f2f7;
}

.folder-item .green-selected-line {
    background-color: #ffffff;
    transition: background-color 0.3s ease;
}

.folder-item-selected .green-selected-line {
    background-color: #38b6a0;
    transition: background-color 0.3s ease;
}

.folder-item:hover {
    background-color: rgb(242, 242, 247);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.folder-item:hover .green-selected-line {
    background-color: #38b6a0;
    transition: background-color 0.3s ease;
}

.folder-item-selected:hover .green-selected-line {
    background-color: rgb(242, 242, 247);
    transition: background-color 0.3s ease;
}

.green-selected-line {
    width: 6px;
}

.folder-icon {
    width: 33px;
    margin-top: 4px;
    margin-left: 16px;
}

.folder-item-selected .folder-icon {
    margin-top: 4px;
    margin-left: 16px;
}

.sub-category-folder-icon {
    margin: 4px 0 78px 46px;
}

.folder-item-selected .sub-category-folder-icon {
    margin: 4px 0 78px 40px;
}

.folder-details {
    margin: 8px 21px 8px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.folder-name {
    max-width: 372px;
    max-height: 46px;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #26273b;
    text-decoration: none solid rgb(38, 39, 59);
    margin-block-start: 0;
    margin-block-end: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.folder-description {
    max-width: 372px;
    min-height: 21px;
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    color: #737394;
    text-decoration: none solid rgb(115, 115, 148);
    margin-top: 8px;
}

.guide-count {
    height: 19px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #9c9db5;
    text-decoration: none solid rgb(156, 157, 181);
    margin-top: 16px;
}

/* Media query for screens with a width of 280px or larger */
@media screen and (min-width: 280px) {
    .app-name {
        max-width: 60px;
    }
}

/* Media query for screens with a width of 1366px or larger */
@media screen and (min-width: 1366px) {
    .app-name {
        max-width: 285px;
    }
}