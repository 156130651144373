.apps-list-page-container {
    background-color: rgb(242, 242, 247);
    padding: 63px;
    min-height: 100vh;
}

.apps-list-container {
    max-width: 1181px;
    background-size: cover;
    margin: 0 auto;
}

.error-msg {
    background-color: rgb(242, 242, 247);
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #26273b;
    padding: 3rem;
}

.applications-heading-text {
    max-width: 125px;
    height: 28px;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    color: #26273b;
    text-decoration: none solid rgb(38, 39, 59);
    margin: 0 0 16px 3px;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    max-width: 1181px;
    gap: 10px;
}

.empty-app-search-results {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #26273b;
}