.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: #b6b7cc;
    background-color: #ffffff;
    background-size: cover;
    box-shadow: 0px 2px 6px rgba(38, 39, 59, 0.14);
    min-width: 100%;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.logo {
    width: 138px;
    height: 41px;
    background-size: cover;
    margin: 9px 252px 9px 30px;
    cursor: pointer;
}

.search-container {
    position: relative;
    width: 600px;
    height: 38px;
    padding: 0px 8px 0px 50px;
    border-radius: 50px;
    background-color: #f2f2f7;
    background-size: cover;
    margin: 11px 204px 11px 0;
}

.search-box {
    height: 38px;
    width: 100%;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    padding: 10px 40px 10px 0;
    border: none;
    outline: none;
    background-color: #f2f2f7;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    color: #26273b;
    text-decoration: none solid rgb(38, 39, 59);
}

.search-icon {
    position: absolute;
    fill: #333;
    width: 19px;
    height: 19px;
    top: 10px;
    left: 17px;
}

.language-dropdown {
    margin-right: 23px;
    width: 143px;
    height: 27px;
    padding: 0px 8px;
    border: 1px solid #9c9db5;
    border-radius: 6px;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #26273b;
    text-decoration: none solid rgb(38, 39, 59);
}

.me-user-icon {
    width: 34px;
    height: 34px;
    fill: #464761;
    margin-right: 16px;
}